<template>
  <div>
    <v-card flat class="ma-0 pa-0">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-card outlined flat>
              <v-card-title
                >Usage Overview
                <v-btn icon @click="getPlatformMetrics"
                  ><v-icon>refresh</v-icon></v-btn
                ></v-card-title
              >
              <v-card-text>
                <v-row>
                  <v-col cols="12" xs="12" sm="6">
                    <v-card
                      outlined
                      class="text-center"
                      style="border: 1px solid orange; border-radius: 20px"
                    >
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon color="orange">person</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="text-wrap">
                            Daily Active Users
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip v-if="!loadingMetrics">{{
                            metrics.dailyActiveUsers
                          }}</v-chip>
                          <v-progress-circular
                            v-else
                            indeterminate
                            size="18"
                            color="orange"
                          ></v-progress-circular>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col cols="12" xs="12" sm="6">
                    <v-card
                      outlined
                      class="text-center"
                      style="border: 1px solid orange; border-radius: 20px"
                    >
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon color="orange">person</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="text-wrap">
                            Monthly Active Users
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip v-if="!loadingMetrics">{{
                            metrics.monthlyActiveUsers
                          }}</v-chip>
                          <v-progress-circular
                            v-else
                            indeterminate
                            size="18"
                            color="orange"
                          ></v-progress-circular>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </v-col>

                  <!-- <v-col cols="12" xs="12" sm="6">
                    <v-card
                      outlined
                      class="text-center"
                      style="border: 1px solid green; border-radius: 20px"
                    >
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon color="green">directions_boat</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="text-wrap">
                            Total Shipments
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip v-if="!loadingMetrics">{{
                            metrics.totalShipments
                          }}</v-chip>
                          <v-progress-circular
                            v-else
                            indeterminate
                            size="18"
                            color="green"
                          ></v-progress-circular>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </v-col>

                  <v-col cols="12" xs="12" sm="6">
                    <v-card
                      outlined
                      class="text-center"
                      style="border: 1px solid green; border-radius: 20px"
                    >
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon color="green">description</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="text-wrap">
                            Open Files
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip v-if="!loadingMetrics">{{
                            metrics.activeShipments
                          }}</v-chip>
                          <v-progress-circular
                            v-else
                            indeterminate
                            size="18"
                            color="green"
                          ></v-progress-circular>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </v-col> -->

                  <!-- <v-col cols="12" xs="12" sm="6">
                    <v-card
                      outlined
                      class="text-center"
                      style="border: 1px solid grey; border-radius: 20px"
                    >
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon color="grey">document_scanner</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="text-wrap">
                            OCR Documents
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip v-if="!loadingMetrics">{{
                            metrics.totalDocuments
                          }}</v-chip>
                          <v-progress-circular
                            v-else
                            indeterminate
                            size="18"
                            color="grey"
                          ></v-progress-circular>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </v-col>

                  <v-col cols="12" xs="12" sm="6">
                    <v-card
                      outlined
                      class="text-center"
                      style="border: 1px solid grey; border-radius: 20px"
                    >
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon color="grey">description</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="text-wrap">
                            Total Pages Extracted
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip v-if="!loadingMetrics">{{
                            metrics.totalPages
                          }}</v-chip>
                          <v-progress-circular
                            v-else
                            indeterminate
                            size="18"
                            color="grey"
                          ></v-progress-circular>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </v-col> -->

                  <v-col cols="12" xs="12" sm="4">
                    <v-card flat class="text-left">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-wrap"
                            style="font-size: 14px; color: grey"
                          >
                            {{ signingUsage.totalSales }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ signingUsage.total }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Signed Documents
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip>
                            {{ kFormatter(signingUsage.total) }}
                          </v-chip>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col cols="12" xs="12" sm="4">
                    <v-card flat class="text-left">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-wrap"
                            style="font-size: 14px; color: grey"
                          >
                            {{ signingUsage.previousMonthSales }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ signingUsage.previousMonth }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Previous Month
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip>
                            {{ kFormatter(signingUsage.previousMonth) }}
                          </v-chip>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col cols="12" xs="12" sm="4">
                    <v-card flat class="text-left">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-wrap"
                            style="font-size: 14px; color: grey"
                          >
                            {{ signingUsage.currentMonthSales }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ signingUsage.currentMonth }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Current Month
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip>
                            {{ kFormatter(signingUsage.currentMonth) }}
                          </v-chip>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card outlined flat>
              <v-card-title
                >Customer Usage
                <v-btn icon @click="getCustomerBreakdown"
                  ><v-icon>refresh</v-icon></v-btn
                ></v-card-title
              >
              <v-card-text>
                <v-progress-linear
                  indeterminate
                  v-if="loadingCustomers"
                ></v-progress-linear>
                <v-row>
                  <v-col
                    cols="12"
                    v-for="customer in customers"
                    :key="customer.organisationId"
                  >
                    <v-card outlined flat>
                      <v-card-title>
                        <v-avatar
                          size="32"
                          class="mr-2"
                          :color="
                            customer.organisation.logo ? 'white' : 'secondary'
                          "
                        >
                          <v-img
                            contain
                            v-if="customer.organisation.logo"
                            :src="customer.organisation.logo"
                          ></v-img>
                          <h3 v-else>
                            {{ customer.organisation.name.charAt(0) }}
                          </h3>
                        </v-avatar>
                        {{ customer.organisation.name }} <v-spacer></v-spacer>
                      </v-card-title>
                      <v-card-text class="my-0 py-0">
                        <v-list dense>
                          <v-list-item
                            v-for="(usage, index) in customer.usage"
                            :key="index"
                          >
                            <v-list-item-content>
                              <v-list-item-title
                                >Period: {{ usage.month }}</v-list-item-title
                              >
                              <v-list-item-subtitle
                                >Total: ${{ usage.total }}</v-list-item-subtitle
                              >
                            </v-list-item-content>
                            <v-list-item-content>
                              <ul style="font-size: 12px; color: grey">
                                <li
                                  v-for="(
                                    moduleUsage, moduleIndex
                                  ) in usage.items"
                                  :key="moduleIndex"
                                >
                                  {{ moduleUsage.model }} - ${{
                                    moduleUsage.amount
                                  }}
                                  ({{ moduleUsage.quantity }} items)
                                  <v-btn
                                    icon
                                    small
                                    @click="
                                      downloadFile(
                                        usage.key,
                                        usage.month,
                                        moduleUsage.model.toLowerCase()
                                      )
                                    "
                                  >
                                    <v-icon small>download</v-icon>
                                  </v-btn>
                                </li>
                              </ul>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row> </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card outlined flat>
              <v-card-title>
                Bundles <v-spacer></v-spacer>
                <v-btn color="primary" @click="addBundle" icon
                  ><v-icon>add_circle_outline</v-icon></v-btn
                >
              </v-card-title>
              <v-card-text :key="xeroKey">
                <v-progress-linear
                  indeterminate
                  v-if="loadingBundle"
                ></v-progress-linear>
                <v-list subheader dense>
                  <v-list-item v-if="bundles.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No bundles</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="item in bundles"
                    :key="item.id"
                    @click="editBundle(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                        <div
                          v-if="
                            item.productNumber &&
                            xeroProduct(item.productNumber)
                          "
                        >
                          <v-avatar size="60" class="my-0 py-0">
                            <v-img
                              contain
                              src="https://cdn.loglive.io/icon/xero/xero.png"
                            />
                          </v-avatar>
                          {{ xeroProduct(item.productNumber) }}
                        </div>
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 12px">
                        {{ item.description }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon class="mr-1" small>attach_money</v-icon>
                        <span>{{ item.rateType }}</span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="item.isDefault">
                        <v-icon color="blue" class="mr-1" small
                          >verified</v-icon
                        >
                        Default Bundle
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon
                          class="mr-1"
                          small
                          v-if="item.bundleModules.length > 0"
                          >list</v-icon
                        >
                        <span
                          v-for="(
                            bundleModule, bundleIndex
                          ) in item.bundleModules"
                          :key="bundleModule.id"
                        >
                          {{ bundleModule.systemModule.name }}
                          <span
                            v-if="bundleIndex < item.bundleModules.length - 1"
                          >
                            -
                          </span></span
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="text-center">
                      <v-chip>$ {{ item.rate }} </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>

            <v-card outlined flat>
              <v-card-title>
                Subscriptions<v-spacer></v-spacer>
                <v-btn color="primary" @click="addSubscription(false)" icon
                  ><v-icon>add_circle_outline</v-icon></v-btn
                >
              </v-card-title>
              <v-card-text class="my-0 py-0">
                <v-progress-linear
                  indeterminate
                  v-if="loadingSubscription"
                ></v-progress-linear>
                <v-list subheader dense>
                  <v-list-item v-if="standardSubscriptions.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No active subscriptions</span>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-for="sub in standardSubscriptions"
                    :key="sub.id"
                    @click="editSubscription(sub)"
                  >
                    <v-list-item-action v-if="!$vuetify.breakpoint.mobile">
                      <v-avatar
                        style="background: white"
                        v-if="sub.organisation.logo"
                        ><v-img contain :src="sub.organisation.logo"></v-img
                      ></v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        sub.organisation.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <span
                          v-for="bundle in sub.subscriptionBundles"
                          :key="bundle.id"
                        >
                          <v-chip small class="mx-1"
                            ><v-icon small left>local_offer</v-icon>
                            {{ bundle.bundle.name }}
                            <span v-if="bundle.discount" class="ml-1">
                              (-{{ bundle.discount }}%)</span
                            ></v-chip
                          >
                        </span></v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-row justify="center" align="center">
                            <v-tooltip top v-if="!sub.activateBilling">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="danger">
                            <v-icon>money_off</v-icon>
                          </v-btn>
                        </template>
                        <span>Billing: Inactive</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="green">
                            <v-icon>attach_money</v-icon>
                          </v-btn>
                        </template>
                        <span>Billing Active</span>
                      </v-tooltip>
                      <v-tooltip top v-if="sub.suspended">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="danger">
                            <v-icon>block</v-icon>
                          </v-btn>
                        </template>
                        <span>Suspended</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="green">
                            <v-icon>task_alt</v-icon>
                          </v-btn>
                        </template>
                        <span>Active</span>
                      </v-tooltip>
                        </v-row>
                    
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>

            <v-card outlined flat>
              <v-card-title>
                Reverse Subscriptions <v-spacer></v-spacer>
                <v-btn color="primary" @click="addSubscription(true)" icon
                  ><v-icon>add_circle_outline</v-icon></v-btn
                >
              </v-card-title>
              <v-card-text>
                <v-progress-linear
                  indeterminate
                  v-if="loadingSubscription"
                ></v-progress-linear>
                <v-list subheader dense>
                  <v-list-item v-if="reverseSubscriptions.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No reverse subscriptions</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="sub in reverseSubscriptions"
                    :key="sub.id"
                    @click="editSubscription(sub)"
                  >
                    <v-list-item-action v-if="!$vuetify.breakpoint.mobile">
                      <v-avatar
                        v-if="sub.organisation.logo"
                        style="background: white"
                        ><v-img contain :src="sub.organisation.logo"></v-img
                      ></v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        sub.organisation.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-icon small>attach_money</v-icon>
                        {{ sub.billingOrganisation.name }}</v-list-item-subtitle
                      >

                      <v-list-item-subtitle>
                        <span
                          v-for="bundle in sub.subscriptionBundles"
                          :key="bundle.id"
                        >
                          <v-chip small class="mx-1"
                            ><v-icon small left>local_offer</v-icon>
                            {{ bundle.bundle.name }}
                            <span v-if="bundle.discount" class="ml-1">
                              (-{{ bundle.discount }}%)</span
                            ></v-chip
                          >
                        </span></v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-row justify="center" align="center">
                            <v-tooltip top v-if="!sub.activateBilling">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="danger">
                            <v-icon>money_off</v-icon>
                          </v-btn>
                        </template>
                        <span>Billing: Inactive</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="green">
                            <v-icon>attach_money</v-icon>
                          </v-btn>
                        </template>
                        <span>Billing Active</span>
                      </v-tooltip>
                      <v-tooltip top v-if="sub.suspended">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="danger">
                            <v-icon>block</v-icon>
                          </v-btn>
                        </template>
                        <span>Suspended</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="green">
                            <v-icon>task_alt</v-icon>
                          </v-btn>
                        </template>
                        <span>Active</span>
                      </v-tooltip>
                        </v-row>
                    
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="bundleModal"
      persistent
      width="1200px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage Bundle </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="!bundle.name || !bundle.description"
            @click="saveBundle"
            :loading="savingBundle"
            >Save</v-btn
          >
          <v-btn text @click="bundleModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                label="Name*"
                dense
                outlined
                clearable
                v-model="bundle.name"
              ></v-text-field>
              <!-- <v-text-field label="Product Number" dense outlined clearable
                                v-model="bundle.productNumber"></v-text-field> -->
              <v-autocomplete
                label="Product Account"
                v-model="bundle.productNumber"
                outlined
                dense
                :loading="loadingXero"
                :items="xeroAccounts"
                item-text="Name"
                item-value="Code"
              ></v-autocomplete>
              <v-textarea
                rows="5"
                auto-grow
                label="Description*"
                outlined
                clearable
                v-model="bundle.description"
              ></v-textarea>
              <v-row align="center">
                <v-col cols="12" xs="12" sm="6">
                  <v-select
                    label="Rate Type"
                    v-model="bundle.rateType"
                    outlined
                    dense
                    :items="priceTypes"
                  ></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    label="Rate per unit"
                    prefix="$"
                    outlined
                    clearable
                    dense
                    v-model="bundle.rate"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              :key="refreshModules"
              v-if="bundle && bundle.bundleModules"
            >
              <v-switch
                label="This is a default bundle"
                color="green"
                v-model="bundle.isDefault"
              ></v-switch>
              <v-subheader>Modules</v-subheader>
              <v-chip
                v-for="(item, index) in modules"
                :key="index"
                class="ma-2"
                outlined
                @click="changeModuleSubscription(item)"
                :color="
                  bundle.bundleModules
                    .map((x) => x.systemModuleId)
                    .includes(item.id)
                    ? 'success'
                    : 'grey'
                "
              >
                <v-icon
                  small
                  left
                  v-if="
                    bundle.bundleModules
                      .map((x) => x.systemModuleId)
                      .includes(item.id)
                  "
                  >check</v-icon
                >
                {{ item.name }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card></v-dialog
    >

    <!-- Subscription -->
    <v-dialog
      v-model="subscriptionModal"
      persistent
      width="1200px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage Subscription </v-toolbar-title>
          <v-spacer></v-spacer>
          
          <v-chip
            @click="subscriptionItem.activateBilling = !subscriptionItem.activateBilling"
            v-if="subscriptionItem.activateBilling"
            color="green" class="mr-2"
            ><v-icon left>attach_money</v-icon> Billing: Active</v-chip
          >
          <v-chip
            @click="subscriptionItem.activateBilling = !subscriptionItem.activateBilling"
            v-else-if="!subscriptionItem.activateBilling"
            color="danger" class="mr-2"
            ><v-icon left>money_off</v-icon> Billing: Inactve</v-chip
          >
          <v-chip
            @click="subscriptionItem.suspended = !subscriptionItem.suspended"
            v-if="subscriptionItem.id && !subscriptionItem.suspended"
            color="green"
            ><v-icon left>task_alt</v-icon> Account Active</v-chip
          >
          <v-chip
            @click="subscriptionItem.suspended = !subscriptionItem.suspended"
            v-else-if="subscriptionItem.id && subscriptionItem.suspended"
            color="danger"
            ><v-icon left>block</v-icon> Suspended</v-chip
          >

          <v-btn
            color="primary"
            text
            :disabled="
              (!subscriptionItem.organisationId &&
                !subscriptionItem.reverseBilling) ||
              ((!subscriptionItem.organisationId ||
                !subscriptionItem.billingOrganisationId) &&
                subscriptionItem.reverseBilling)
            "
            @click="saveSubscription"
            :loading="savingSubscription"
            >Save</v-btn
          >
          <v-btn text @click="subscriptionModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                id="organisationName"
                outlined
                dense
                hide-no-data
                clearable
                label="Organisation*"
                v-model="subscriptionItem.organisationId"
                :loading="searchingOrganisations"
                :search-input.sync="searchOrganisations"
                :menu-props="{ closeOnContentClick: true }"
                :items="organisations.data"
                item-text="name"
                item-value="id"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-action>
                    <v-avatar v-if="item.logo" style="background: white"
                      ><v-img contain :src="item.logo"></v-img
                    ></v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-autocomplete
                id="organisationName"
                outlined
                dense
                hide-no-data
                clearable
                v-if="subscriptionItem.reverseBilling"
                label="Billed Organisation*"
                v-model="subscriptionItem.billingOrganisationId"
                :loading="searchingBillingOrganisations"
                :search-input.sync="searchBillingOrganisations"
                :menu-props="{ closeOnContentClick: true }"
                :items="billingOrganisations.data"
                item-text="name"
                item-value="id"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-action>
                    <v-avatar v-if="item.logo" style="background: white"
                      ><v-img contain :src="item.logo"></v-img
                    ></v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-autocomplete
                label="Xero Organisation"
                v-model="subscriptionItem.xeroContactId"
                outlined
                dense
                clearable
                :items="xeroContacts"
                item-text="Name"
                item-value="ContactID"
              >
              <template v-slot:append-item>
                <v-divider class="mb-2"></v-divider>
                <v-list-item
                  style="height: 30px"
                  @click="addAccountingContact()"
                >
                  <v-list-item-content class="text-center">
                    <v-list-item-title class="text-center">
                      <v-btn color="primary" small text>Create New</v-btn>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                </template>
              </v-autocomplete>
              <v-select
                label="Billing Frequency"
                dense
                v-model="subscriptionItem.billingFrequency"
                outlined
                :items="['Monthly', 'Annually']"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-subheader>Bundles</v-subheader>
              <v-list
                dense
                subheader
                style="max-height: 50vh; overflow-y: auto"
                v-if="subscriptionItem && subscriptionItem.subscriptionBundles"
              >
                <v-list-item v-for="item in bundles" :key="item.id">
                  <v-list-item-action-text class="text-center mx-2">
                    <v-chip>$ {{ item.rate }} </v-chip>
                    <br />
                    <span style="font-size: 10px">{{ item.rateType }}</span>
                  </v-list-item-action-text>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 12px">
                      {{ item.description }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <span
                        v-for="(
                          bundleModule, bundleIndex
                        ) in item.bundleModules"
                        :key="bundleModule.id"
                      >
                        {{ bundleModule.systemModule.name }}
                        <span
                          v-if="bundleIndex < item.bundleModules.length - 1"
                        >
                          -
                        </span></span
                      >
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action :key="subscriptionkey">
                    <v-tooltip
                      top
                      v-if="
                        !subscriptionItem.subscriptionBundles
                          .map((x) => x.bundleId)
                          .includes(item.id)
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="changeBundleSubscription(item)"
                        >
                          <v-icon>add_card</v-icon>
                        </v-btn>
                      </template>
                      <span>Add subscription</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="changeBundleSubscription(item)"
                        >
                          <v-icon color="blue">verified</v-icon>
                        </v-btn>
                      </template>
                      <span>Subscribed</span>
                    </v-tooltip>

                    <v-tooltip
                      top
                      v-if="
                        subscriptionItem.subscriptionBundles
                          .map((x) => x.bundleId)
                          .includes(item.id)
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-chip small v-on="on" @click="editDiscount(item)"
                          ><v-icon small left>discount</v-icon>
                          {{ bundleDiscount(item) }} %</v-chip
                        >
                      </template>
                      <span>Applied Discount</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="discountModal"
      width="250px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="updateDiscount" color="success" icon
            ><v-icon>check</v-icon></v-btn
          >
          <v-btn text @click="discountModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            suffix="%"
            v-model="discountItem.discount"
            type="number"
            :min="0"
            :max="100"
          ></v-text-field>
        </v-card-text> </v-card
    ></v-dialog>

    <v-dialog
      v-model="customerUsageModal"
      width="1200px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>
          {{}} <v-spacer></v-spacer
          ><v-btn text @click="customerUsageModal = false">X</v-btn>
        </v-card-title>
        <v-card-text style="min-height: 40vh">
          <v-row>
            <v-col cols="12" sm="4">
              <DynamicSmoothLineChart
                :data="customerUsage.shipments.data"
                :key="graphKey"
                :labels="customerUsage.shipments.months"
                defaultChart="line"
                xAxisLabel="Shipments"
                :textColor="'grey'"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <DynamicSmoothLineChart
                :data="customerUsage.containers.data"
                :key="graphKey"
                :labels="customerUsage.containers.months"
                defaultChart="line"
                xAxisLabel="Containers"
                :textColor="'grey'"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <DynamicSmoothLineChart
                :data="customerUsage.pallets.data"
                :key="graphKey"
                :labels="customerUsage.pallets.months"
                defaultChart="line"
                xAxisLabel="Pallets"
                :textColor="'grey'"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="fileBreakdownModal"
      width="1200px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>
          Usage Breakdown <v-spacer></v-spacer
          ><v-btn text @click="fileBreakdownModal = false">X</v-btn>
        </v-card-title>
        <v-card-text style="min-height: 40vh">
          <v-row> </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="accountingContactModal" width="900px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> Create Contact </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            :disabled="!accountingContact.name"
            @click="saveAccountingContact"
            :loading="savingAccountingContact"
          >
            <v-icon> save </v-icon>
          </v-btn>
          <v-btn text @click="accountingContactModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Name*"
                  v-model="accountingContact.name"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  label="Account Reference"
                  v-model="accountingContact.accountReference"
                  outlined
                  dense
                ></v-text-field>
                <v-autocomplete
                  label="Currency*"
                  v-model="accountingContact.currency"
                  outlined
                  dense
                  :items="currencies"
                ></v-autocomplete>
                <v-text-field
                  label="Tax Number"
                  v-model="accountingContact.taxNumber"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  label="Email Address"
                  v-model="accountingContact.emailAddress"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                v-if="accountingContact && accountingContact.address"
              >
                <v-subheader
                  ><v-icon small class="mr-2">location_on</v-icon
                  >Address</v-subheader
                >
                <v-text-field
                  dense
                  label="Address Line 1"
                  hide-details
                  class="my-1"
                  v-model="accountingContact.address.addressLine1"
                  outlined
                ></v-text-field>
                <v-text-field
                  dense
                  label="Address Line 2"
                  hide-details
                  class="my-2"
                  v-model="accountingContact.address.addressLine2"
                  outlined
                ></v-text-field>
                <v-text-field
                  dense
                  label="Address Line 3"
                  hide-details
                  class="my-2"
                  v-model="accountingContact.address.addressLine3"
                  outlined
                ></v-text-field>
                <v-text-field
                  dense
                  label="City"
                  hide-details
                  class="my-2"
                  v-model="accountingContact.address.city"
                  outlined
                ></v-text-field>
                <v-text-field
                  dense
                  label="Region"
                  hide-details
                  class="my-2"
                  v-model="accountingContact.address.region"
                  outlined
                ></v-text-field>
                <v-text-field
                  dense
                  label="Postal Code"
                  hide-details
                  class="my-2"
                  v-model="accountingContact.address.postalCode"
                  outlined
                ></v-text-field>
                <v-text-field
                  dense
                  label="Country"
                  hide-details
                  class="my-2"
                  v-model="accountingContact.address.country"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DynamicSmoothLineChart from "../Charts/DynamicSmoothLineChart.vue";
import * as CurrencyCodes from "currency-codes";

export default {
  components: {
    DynamicSmoothLineChart,
  },
  data: () => ({
    accountingContactModal: false,
    accountingContact: {},
    bundle: {},
    bundles: [],
    bundleModal: false,
    currencies: CurrencyCodes.codes(),
    customers: [],
    customerUsage: {
      shipments: {
        months: [],
        data: [],
      },
      containers: {
        months: [],
        data: [],
      },
      pallets: {
        months: [],
        data: [],
      },
    },
    customerUsageModal: false,

    discountModal: false,
    discountItem: {},
    fileBreakdownModal: false,
    graphKey: 5000,
    loadingCustomers: false,
    loadingMetrics: false,
    loadingSubscription: false,
    loadingBundle: false,
    loadingCustomerUsage: null,

    metrics: {},
    modules: [],
    loadingXero: false,
    priceTypes: [
      "None",
      "Per Pallet",
      "Per Container",
      "Per Shipment",
      "Flat Rate",
      "Per Page",
    ],
    refreshModules: 0,
    savingBundle: false,
    organisationTimeout: null,
    organisations: { data: [] },
    searchingOrganisations: false,
    searchOrganisations: null,

    billingOrganisationTimeout: null,
    billingOrganisations: { data: [] },
    savingAccountingContact: false,
    searchingBillingOrganisations: false,
    searchBillingOrganisations: null,
    signingUsage: {},
    subscriptionModal: false,
    subscriptionItem: {},
    subscriptions: [],
    subscriptionkey: 0,
    savingSubscription: false,

    xeroAccounts: [],
    xeroContacts: [],
    xeroKey: 8000,
  }),
  async created() {
    this.getCustomerBreakdown();
    this.getPlatformMetrics();
    this.getModules();
    this.getBundles();
    this.getSubscriptions();
    this.getXeroAccounts();
  },
  computed: {
    standardSubscriptions() {
      return this.subscriptions.filter((x) => !x.reverseBilling);
    },
    reverseSubscriptions() {
      return this.subscriptions.filter((x) => x.reverseBilling);
    },
  },
  watch: {
    async searchBillingOrganisations(val) {
      if (this.searchingBillingOrganisations) {
        clearTimeout(this.billingOrganisationTimeout);
      }
      if (val && val.length > 2) {
        this.billingOrganisationTimeout = setTimeout(async () => {
          this.searchingBillingOrganisations = true;
          this.billingOrganisations = await this.$API.listOrganisationsAdmin({
            params: {
              search: val,
              limit: 15,
            },
          });
          this.searchingBillingOrganisations = false;
        }, 150);
        this.searchingBillingOrganisations = false;
      } else {
        clearTimeout(this.billingOrganisationTimeout);
        this.searchingBillingOrganisations = false;
      }
    },
    async searchOrganisations(val) {
      if (this.searchingOrganisations) {
        clearTimeout(this.organisationTimeout);
      }
      if (val && val.length > 2) {
        this.organisationTimeout = setTimeout(async () => {
          this.searchingOrganisations = true;
          this.organisations = await this.$API.listOrganisationsAdmin({
            params: {
              search: val,
              limit: 15,
            },
          });
          this.searchingOrganisations = false;
        }, 150);
        this.searchingOrganisations = false;
      } else {
        clearTimeout(this.organisationTimeout);
        this.searchingOrganisations = false;
      }
    },
  },
  methods: {
    addAccountingContact() {
      let findOrganisation = this.organisations.data.find(
        (x) => x.id == this.subscriptionItem.organisationId
      );
      console.log(findOrganisation)
      let address =
      findOrganisation.organisationAddresses.find(
          (a) => a.isHQ
        ) ?? findOrganisation.organisationAddresses.length > 0
          ? findOrganisation.organisationAddresses[0]
          : null;
      this.accountingContact = {
        name: findOrganisation.name,
        // accountReference: this.company.accountReference,
        currency: "USD",
        taxNumber: findOrganisation.taxNumber,
        emailAddress: null,
        address: {}
      };
      if (address) {
        this.accountingContact.address = {
          addressType: address.postalCode ? "POBOX" : "STREET",
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          addressLine3: address.addressLine3,
          city: address.town,
          region: address.subdivision,
          postalCode: address.postalCode,
          country: address.country,
        };
      }
      this.accountingContactModal = true;
    },
    addBundle() {
      this.bundle = {
        bundleModules: [],
        rateType: this.priceTypes[0],
      };
      this.bundleModal = true;
    },
    addSubscription(reverse = false) {
      this.subscriptionItem = {
        billingFrequency: "Monthly",
        reverseBilling: reverse,
        subscriptionBundles: [],
        suspended: false,
        activateBilling: false,
      };
      this.subscriptionModal = true;
    },
    bundleDiscount(item) {
      let find = this.subscriptionItem.subscriptionBundles.find(
        (x) => x.bundleId == item.id
      );
      return find.discount;
    },
    async downloadFile(key, month, type) {
      let dataKey = key + type + ".csv";
      let data = await this.$API.downloadCustomerMetrics(dataKey);
      let link = document.createElement("a");
      link.href = data;
      link.download = `${type}_${month}.csv`;
      link.click();
    },
    editBundle(item) {
      this.bundle = item;
      this.bundleModal = true;
    },
    editDiscount(item) {
      let find = this.subscriptionItem.subscriptionBundles.find(
        (x) => x.bundleId == item.id
      );
      this.discountItem = find;
      this.discountModal = true;
    },
    editSubscription(item) {
      this.subscriptionItem = item;
      this.organisations.data.push(this.subscriptionItem.organisation);
      this.billingOrganisations.data.push(
        this.subscriptionItem.billingOrganisation
      );
      this.subscriptionModal = true;
    },
    changeModuleSubscription(item) {
      if (
        this.bundle.bundleModules.map((x) => x.systemModuleId).includes(item.id)
      ) {
        this.bundle.bundleModules = this.bundle.bundleModules.filter(
          (x) => x.systemModuleId != item.id
        );
      } else {
        let findModule = this.modules.find((x) => x.id == item.id);
        this.bundle.bundleModules.push({
          systemModuleId: item.id,
          systemModule: findModule,
        });
      }
      this.refreshModules++;
    },
    async changeBundleSubscription(item) {
      if (
        this.subscriptionItem.subscriptionBundles
          .map((x) => x.bundleId)
          .includes(item.id)
      ) {
        let find = this.subscriptionItem.subscriptionBundles.find(
          (x) => x.bundleId == item.id
        );

        this.subscriptionItem.subscriptionBundles =
          this.subscriptionItem.subscriptionBundles.filter(
            (x) => x.bundleId != item.id
          );
        if (find && find.id) {
          await this.$API.updateSubscriptionBundle({
            id: find.id,
            isActive: false,
            isDeleted: true,
          });
        }
      } else {
        let findBundle = this.bundles.find((x) => x.id == item.id);
        this.subscriptionItem.subscriptionBundles.push({
          bundleId: item.id,
          bundle: findBundle,
          discount: 0,
        });
        if (this.subscriptionItem.id) {
          await this.$API.createSubscriptionBundle({
            subscriptionId: this.subscriptionItem.id,
            bundleId: item.id,
            discount: 0,
          });
        }
      }
      this.subscriptionkey++;
    },
    async getPlatformMetrics() {
      this.getSigningUsage();
      this.loadingMetrics = true;
      this.metrics = await this.$API.getPlatformMetrics();
      this.loadingMetrics = false;
    },
    async getCustomerBreakdown() {
      this.loadingCustomers = true;
      this.customers = await this.$API.getCustomerPlatformMetrics();
      this.loadingCustomers = false;
    },
    async getBundles() {
      this.loadingBundle = true;
      this.bundles = await this.$API.getBundles();
      this.loadingBundle = false;
    },
    async getModules() {
      let modules = await this.$API.getModules({});
      this.modules = modules.data;
    },
    async getSigningUsage() {
      let date = new Date();
      let previous = new Date();
      previous.setMonth(previous.getMonth() - 1);

      let usage = await this.$API.getDocumentSigningUsage();
      this.signingUsage = {
        total: usage.map((x) => x.signed).reduce((a, b) => a + b, 0),
        totalSales:
          "$" +
          usage
            .map((x) => x.signedSales)
            .reduce((a, b) => a + b, 0)
            .toFixed(2),
        currentMonth: usage
          .filter(
            (x) =>
              x.month == date.getMonth() + 1 && x.year == date.getFullYear()
          )
          .map((x) => x.signed)
          .reduce((a, b) => a + b, 0),
        currentMonthSales:
          "$" +
          usage
            .filter(
              (x) =>
                x.month == date.getMonth() + 1 && x.year == date.getFullYear()
            )
            .map((x) => x.signedSales)
            .reduce((a, b) => a + b, 0)
            .toFixed(2),
        previousMonth: usage
          .filter(
            (x) =>
              x.month == previous.getMonth() + 1 &&
              x.year == previous.getFullYear()
          )
          .map((x) => x.signed)
          .reduce((a, b) => a + b, 0),
        previousMonthSales:
          "$" +
          usage
            .filter(
              (x) =>
                x.month == previous.getMonth() + 1 &&
                x.year == previous.getFullYear()
            )
            .map((x) => x.signedSales)
            .reduce((a, b) => a + b, 0)
            .toFixed(2),
      };
      console.log(this.signingUsage);
    },
    async getSubscriptions() {
      this.loadingSubscription = true;
      this.subscriptions = await this.$API.getSubscriptions();
      this.loadingSubscription = false;
    },
    async getXeroAccounts() {
      this.loadingXero = true;
      this.xeroAccounts = await this.$API.getXeroAccounts();
      this.xeroContacts = await this.$API.getXeroContacts();
      this.xeroKey++;
      this.loadingXero = false;
    },
    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },
    async saveAccountingContact() {
      this.savingAccountingContact = true;
      try {
        let contactResult = await this.$API.createXeroContact(
          this.accountingContact
        );
        this.$message.success("Successfully Created!");
        if (contactResult && contactResult.ContactID) {
          this.subscriptionItem.xeroContactId = contactResult.ContactID;
        //   this.company.accountReference = contactResult.accountReference;
        //   this.subscriptionItem.accountsReceivableTotal =
        //     contactResult.accountsReceivableTotal;
        //   this.subscriptionItem.accountsPayableTotal =
        //     contactResult.accountsPayableTotal;
        //   this.subscriptionItem.accountsReceivableOverdue =
        //     contactResult.accountsReceivableOverdue;
        //   this.subscriptionItem.accountsPayableOverdue =
        //     contactResult.accountsPayableOverdue;
          this.xeroContacts.push(contactResult);
        }
        // this.getAccountingContacts();
        this.accountingContactModal = false;
        this.savingAccountingContact = false;
      } catch (e) {
        if (e.message) {
          this.$message.error(e.message);
        } else {
          this.$message.error("An error occurred");
        }
        this.savingAccountingContact = false;
      }
    },
    async saveBundle() {
      this.savingBundle = true;
      if (this.bundle.id) {
        await this.$API.updateBundle(this.bundle);
      } else {
        await this.$API.createBundle(this.bundle);
      }
      this.bundleModal = false;
      this.bundle = {};
      this.getBundles();
      this.getSubscriptions();
      this.savingBundle = false;
    },
    async saveSubscription() {
      this.savingSubscription = true;
      if (!this.subscriptionItem.reverseBilling) {
        this.subscriptionItem.billingOrganisationId =
          this.subscriptionItem.organisationId;
      }
      if (this.subscriptionItem.id) {
        await this.$API.updateSubscription(this.subscriptionItem);
      } else {
        await this.$API.createSubscription(this.subscriptionItem);
      }
      this.subscriptionModal = false;
      this.subscriptionItem = {};
      this.getSubscriptions();
      this.savingSubscription = false;
    },
    async updateDiscount() {
      let findIndex = this.subscriptionItem.subscriptionBundles.findIndex(
        (x) => x.bundleId == this.discountItem.id
      );
      this.subscriptionItem.subscriptionBundles[findIndex] = this.discountItem;
      if (this.discountItem.id) {
        await this.$API.updateSubscriptionBundle(this.discountItem);
      }
      this.discountModal = false;
      this.discountItem = {};
    },
    async viewFileBreakdown(organisationId) {
      // this.loadingCustomerUsage = organisationId
      this.customerUsage = await this.$API.getCustomerUsageBreakdown(
        organisationId
      );
      this.fileBreakdownModal = true;
      // this.loadingFileBreakdown = false
    },
    async viewCustomerUsage(organisationId) {
      this.loadingCustomerUsage = organisationId;
      this.customerUsage = await this.$API.getCustomerUsageMetrics(
        organisationId
      );
      this.customerUsage.pallets.data = {
        data: this.customerUsage.pallets.data,
        type: "bar",
        color: "#2196F3",
        symbol: "circle",
        smooth: true,
        showInLegend: true,
        name: "Pallets",
      };
      this.customerUsage.containers.data = {
        data: this.customerUsage.containers.data,
        type: "bar",
        color: "#009688",
        symbol: "circle",
        smooth: true,
        showInLegend: true,
        name: "Containers",
      };
      this.customerUsage.shipments.data = {
        data: this.customerUsage.shipments.data,
        type: "bar",
        color: "#3F51B5",
        symbol: "circle",
        smooth: true,
        showInLegend: true,
        name: "Shipments",
      };
      console.log(this.customerUsage);
      this.customerUsageModal = true;
      this.loadingCustomerUsage = null;
      setTimeout(() => {
        this.graphKey++;
      }, 250);
    },
    xeroProduct(item) {
      let find = this.xeroAccounts.find((x) => x.Code == item);
      return find ? find.Name : null;
    },
  },
};
</script>
